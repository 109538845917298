export const versionName = 'WebApp';

export const versionNum = 77;

export const APIDomain = 'https://sentinimarinecbt-api.azurewebsites.net';
//export const APIDomain = 'http://192.168.7.211';

export const APIRoute = APIDomain + '/api/';

export const LoginRoute = 'https://sentinimarineaccountsservice-api.azurewebsites.net/api/';

export const SSOKey = 'PtRaDxf4T7i65jorHBHyqLSybQCTs6Q93YgehKkSfm8maFdoSoQfyCcSBy5M3FbrMDTzPe6zEYLQEetzCySi';

export const APIPath = {
    // Users
    PASSWORD_REQUIREMENTS: 'User/PasswordRequirements',
    LOGIN: 'User/LogIn',
    LOGIN_PASSWORD_RESET: 'User/LogInWithPasswordReset',
    LOGIN_GOOGLE: 'User/LoginWithGoogle',
    LOGOUT: 'User/LogOut',
    REGISTER: 'User/Register',
    REGISTER_GOOGLE: 'User/RegisterWithGoogle',
    EMAIL_CONFIRMATION: 'User/EmailConfirmation',
    FORGOT_PASSWORD: 'User/ForgotPassword',
    RESET_PASSWORD: 'User/ResetPassword',
    CHANGE_PASSWORD: 'User/ChangePassword',
    GET_USER_INFO: 'User/GetUsersInfo',
    REQUEST_EMAIL_CHANGE: 'User/RequestChangeEmail',
    CONFIRM_EMAIL_CHANGE: 'User/ConfirmChangeEmail',
    REQUEST_EMAIL_CONFIRMATION: 'User/RequestEmailConfirmation',
    FINISH_EXTERNAL_LOGIN: 'User/CheckLocalUser',
    FINISH_EXTERNAL_SIGNUP: 'User/CreateLocalUser',
    TWO_FACTOR_GET_KEY: 'User/GetAuthenticatorCode',
    TWO_FACTOR_ADD: 'User/AddAuthentication',
    TWO_FACTOR_REMOVE: 'User/RemoveAuthentication',
    ADD_DEVICE: 'User/AddDevice',
    UPDATE_USER_ALTERNATE_LOGINS: 'User/UpdateUsersAlternateLogins',
    VERIFY_TWO_FACTOR: 'User/VerifyTwoFactor',
    GET_MOTD: 'User/GetMOTD',
    SEEN_MOTD: 'User/SeenMOTD',
    REFRESH_TOKEN: 'User/RefreshToken',
    BAN_USER: 'User/BanUser/',
    UN_BAN_USER: 'User/UnBanUser/',
    CREATE_MAGIC_LINK: 'User/CreateMagicLinks',
    EDIT_MAGIC_LINK: 'User/EditMagicLinks',
    REGISTER_MAGIC_LINK: 'User/RegisterMagicLink',
    RESEND_MAGIC_LINK: 'User/ResendMagicLink',
    REMOVE_MAGIC_LINK: 'User/RemoveMagicLink',
    MY_INFO: 'User/MyInfo',
    GET_RANKS: 'User/Ranks',
    UPDATE_USER_LEAVE: 'User/UpdateUserLeave',
    USER: 'User',

    // Company
    GET_COMPANY_USERS: companyId => `Company/${companyId}/Company/GetUsers`,
    GET_FILTERED_COMPANY_USERS: companyId => `Company/${companyId}/Company/GetFilteredUsers`,
    GET_CURRENT_MAGIC_LINKS: companyId => `Company/${companyId}/Company/GetCurrentMagicLinks`,
    GET_USER_PROFILE_PIC: (companyId, userId) => `Company/${companyId}/Company/GetUserProfilePic?userId=${userId}`,
    GET_MAGIC_LINK_PROFILE_PIC: (companyId, userName) => `Company/${companyId}/Company/GetMagicLinkProfilePic?userName=${userName}`,
    EDIT_USER: companyId => `Company/${companyId}/Company/EditUser`,
    GET_USER_REST_PERIOD_DATES: (companyId, userId) => `Company/${companyId}/Company/GetRestPeriodDates?userId=${userId}`,
    GET_USER_REST_PERIOD_DOCUMENT: companyId => `Company/${companyId}/Company/GetRestPeriodDocument`,
    GET_MASS_REST_PERIOD_DOCUMENT: companyId => `Company/${companyId}/Company/GetMassRestPeriodDocument`,
    SEND_COMPANY_PUSH_NOTIF: companyId => `Company/${companyId}/Company/SendNotification`,
    GET_COMPANIES: companyId => `Company/${companyId}/Company/GetCompanies`,
    ADD_COMPANY: companyId => `Company/${companyId}/Company/AddCompany`,
    UPDATE_COMPANY: companyId => `Company/${companyId}/Company/UpdateCompany`,
    GET_COMPANY_LOGO: companyId => `Company/${companyId}/Company/GetCompanyLogo`,
    SWITCH_COMPANY: companyId => `Company/${companyId}/Company/SwitchCompany`,

    // User Group
    GET_USER_GROUPS: companyId => `Company/${companyId}/UserGroup`,
    ADD_USER_GROUP: companyId => `Company/${companyId}/UserGroup/Add`,
    EDIT_USER_GROUP: companyId => `Company/${companyId}/UserGroup/Edit`,
    DELETE_USER_GROUP: companyId => `Company/${companyId}/UserGroup/Delete/`,

    // Ship
    GET_SHIPS: companyId => `Company/${companyId}/Ship`,
    SHIP_LOGO: (companyId, shipId) => `Company/${companyId}/Ship/ShipLogo/${shipId}`,
    ADD_SHIP: companyId => `Company/${companyId}/Ship/Add`,
    UPDATE_SHIP: companyId => `Company/${companyId}/Ship/Update`,
    DELETE_SHIP: (companyId, shipId) => `Company/${companyId}/Ship/Delete/${shipId}`,
    GET_SHIP_USERS: (companyId, shipId) => `Company/${companyId}/Ship/GetUsers/${shipId}`,
    ADD_USER_TO_SHIP: companyId => `Company/${companyId}/Ship/AddUserToShip`,
    EDIT_USER_TO_SHIP: companyId => `Company/${companyId}/Ship/EditdUserToShip`,
    REMOVE_USER_FROM_SHIP: companyId => `Company/${companyId}/Ship/RemoveUserFromShip`,

    // Documentation
    GET_COMPANY_ROOT_CATEGORIES: companyId => `Company/${companyId}/Documentation/GetCompanyRootCategories`,
    GET_COMPANY_CATEGORY_CHILDREN: (companyId, categoryId) => `Company/${companyId}/Documentation/GetCompanyCategoryChildren/${categoryId}`,
    GET_COMPANY_CATEGORY_ITEM: (companyId, categoryId, itemId) => `Company/${companyId}/Documentation/GetCompanyCategoryItem/${categoryId}/${itemId}`,
    CREATE_CATEGORY: companyId => `Company/${companyId}/Documentation/CreateCategory`,
    EDIT_CATEGORY: companyId => `Company/${companyId}/Documentation/EditCategory`,
    DELETE_CATEGORY: (companyId, categoryId) => `Company/${companyId}/Documentation/DeleteCategory/${categoryId}`,
    DOC_ADD_SHIP: companyId => `Company/${companyId}/Documentation/AddShip`,
    DOC_REMOVE_SHIP: companyId => `Company/${companyId}/Documentation/RemoveShip`,
    DOC_ADD_USER_GROUP: companyId => `Company/${companyId}/Documentation/AddUserGroup`,
    DOC_REMOVE_USER_GROUP: companyId => `Company/${companyId}/Documentation/RemoveUserGroup`,
    GET_COMPANY_DOCUMENT_ITEMS: companyId => `Company/${companyId}/Documentation/GetCompanysDocumentItems`,
    ADD_DOCUMENT_ITEM: companyId => `Company/${companyId}/Documentation/AddDocumentItem`,
    EDIT_DOCUMENT_ITEM: companyId => `Company/${companyId}/Documentation/EditDocumentItem`,
    DELETE_DOCUMENT_ITEM: (companyId, itemId) => `Company/${companyId}/Documentation/DeleteDocumentItem/${itemId}`,
    ATTACH_DOCUMENT_ITEM: companyId => `Company/${companyId}/Documentation/AttachDocumentItem`,
    REMOVE_DOCUMENT_ITEM: companyId => `Company/${companyId}/Documentation/RemoveDocumentItem`,

    // Question
    GET_QUESTION_CATEGORIES: companyId => `Company/${companyId}/Question/GetQuestionCategorys`,
    ADD_QUESTION_CATEGORIES: companyId => `Company/${companyId}/Question/AddQuestionCategory`,
    EDIT_QUESTION_CATEGORIES: companyId => `Company/${companyId}/Question/EditQuestionCategory`,
    REMOVE_QUESTION_CATEGORIES: (companyId, id) => `Company/${companyId}/Question/RemoveQuestionCategory/${id}`,
    ADD_QUESTION_CATEGORY: companyId => `Company/${companyId}/Question/AddQuestionCategory`,
    EDIT_QUESTION_CATEGORY: companyId => `Company/${companyId}/Question/EditQuestionCategory`,
    REMOVE_QUESTION_CATEGORY: (companyId, categoryId) => `Company/${companyId}/Question/RemoveQuestionCategory/${categoryId}`,
    GET_COMPANY_QUESTIONS: companyId => `Company/${companyId}/Question/GetCompanyQuestions`,
    GET_ALL_QUESTIONS: companyId => `Company/${companyId}/Question/GetAllQuestions`,
    GET_QUESTION: (companyId, questionId) => `Company/${companyId}/Question/GetQuestion/${questionId}`,
    GET_COMPLIANCE_QUESTIONS_BY_CATEGORY: (companyId, complianceId, categoryId) => `Company/${companyId}/Question/GetQuestionsInCategoryForCompiance/${complianceId}/${categoryId}`,
    GET_COMPLIANCE_QUESTION: (companyId, complianceId, questionId) => `Company/${companyId}/Question/GetQuestionForCompiance/${complianceId}/${questionId}`,
    ADD_QUESTION: companyId => `Company/${companyId}/Question/AddQuestion`,
    EDIT_QUESTION: companyId => `Company/${companyId}/Question/EditQuestion`,
    ARCHIVE_QUESTION: (companyId, questionId) => `Company/${companyId}/Question/ArchiveQuestion/${questionId}`,
    DELETE_QUESTION: (companyId, questionId) => `Company/${companyId}/Question/DeleteQuestion/${questionId}`,
    IMPORT_FROM_EXCEL: companyId => `Company/${companyId}/Question/ImportFromExcel`,

    // Compliance Test
    GET_USER_COMPLIANCE_TESTS: (companyId, userId) => `Company/${companyId}/ComplianceTest/GetUserComplianceTests/${userId}`,
    GET_COMPLIANCE_TEST_CATEGORIES: companyId => `Company/${companyId}/ComplianceTest/GetComplianceTestCategories`,
    GET_COMPLIANCE_TESTS: companyId => `Company/${companyId}/ComplianceTest/GetCompanysComplianceTests`,
    GET_COMPLIANCE_TESTS_SPECIFIC: companyId => `Company/${companyId}/ComplianceTest/GetComplianceTests`,
    GET_COMPLIANCE_TEST: (companyId, assessmentId) => `Company/${companyId}/ComplianceTest/GetComplianceTest/${assessmentId}`,
    ADD_COMPLIANCE_TEST: companyId => `Company/${companyId}/ComplianceTest/AddComplianceTest`,
    EDIT_COMPLIANCE_TEST: companyId => `Company/${companyId}/ComplianceTest/EditComplianceTest`,
    COMPANY_COMPLIANCE_TEST_RESULTS: companyId => `Company/${companyId}/ComplianceTest/CompanyComplianceTestResults`,
    SHIP_COMPLIANCE_TEST_RESULTS: (companyId, shipId) => `Company/${companyId}/ComplianceTest/ShipComplianceTestResults/${shipId}`,
    USER_COMPLIANCE_TEST_RESULTS: (companyId, userId) => `Company/${companyId}/ComplianceTest/UsersComplianceTestResults/${userId}`,
    USER_TEST_RESULTS: (companyId, userId, complianceId) => `Company/${companyId}/ComplianceTest/UsersComplianceResult/${userId}/${complianceId}`,
    QUESTION_OVERVIEW: (companyId, shipId = null, userId = null) =>
        `Company/${companyId}/ComplianceTest/QuestionOverview?${shipId !== null ? `shipId=${shipId}${userId !== null ? '&' : ''}` : ''}${userId !== null ? `userId=${userId}` : ''}`,
    ASSESSMENT_OVERVIEW: (companyId, shipId = null) => `Company/${companyId}/ComplianceTest/AssessmentOverview?${shipId !== null ? `shipId=${shipId}` : ''}`,
    USER_GROUPS_OVERVIEW: (companyId, shipId = null) => `Company/${companyId}/ComplianceTest/UserGroupesOverview?${shipId !== null ? `shipId=${shipId}` : ''}`,

    // Notifications
    GET_UNREAD_NOTIF_COUNT: 'Notifications/GetUnreadCount',
    GET_TOP_NOTIFS: 'Notifications/GetTop',
    GET_ALL_NOTIFS: 'Notifications/GetAll',
    MARK_NOTIF_AS_READ: 'Notifications/MarkAsRead/',
    MARK_ALL_NOTIFS_AS_READ: 'Notifications/MarkAllAsRead',

    // File
    FILE_UPlOAD: 'File/Upload',
    FILE_GET_USERS: 'File/GetUsersFiles',

    // Feedback
    FEEDBACK_CREATE_TICKET: 'Feedback/CreateTicket',

    // Version
    GET_VERSION: 'Version',

    // Compliance test levels
    GET_USER_COMPLIANCE_TEST_LEVELS: 'UserComplianceTestLevel/Get',
    EDIT_USER_COMPLIANCE_TEST_LEVELS: 'UserComplianceTestLevel/Edit',
};

export const SIGNALRPath = {
    Notifications: APIDomain + '/webhooks/notification',
};

export const Role = {
    Admin: 'Admin',
};

export const CompanyRole = {
    CrewMember: 'CrewMember',
    Owner: 'Owner',
};

export const CompanyRoles = [
    { name: 'Crew Member', id: CompanyRole.CrewMember },
    { name: 'Superintendent', id: CompanyRole.Owner },
];

export const ShipRole = {
    Captain: 'Captain',
    CrewMember: 'CrewMember',
    Owner: 'Owner',
};

export const ShipRoles = [
    { name: 'Captain', id: ShipRole.Captain },
    { name: 'Crew Member', id: ShipRole.CrewMember },
    { name: 'Superintendent', id: ShipRole.Owner },
];

export const ItemType = {
    Folder: 'Folder',
    File: 'File',
};
