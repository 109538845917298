import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import NoImage from '../../images/no-img.png';
import ComplianceController from '../../controllers/ComplianceController';
import UserGroupController from '../../controllers/UserGroupController';
import ShipController from '../../controllers/ShipController';
import CompanyController from '../../controllers/CompanyController';
import { ButtonBase, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Alert } from '../../components/Common/Alert';
import { isEmpty, isNullOrUndefined, scoreToPercentage } from '../../helpers/Utils';
import { Colours } from '../../helpers/Colours';
import { DashboardGraph } from './DashboardGraph';
import { Close, Done, TrendingDown, TrendingUp } from '@material-ui/icons';
import { clearStore, setCompanyCompliance, setShipCompliance, setUserCompliance, setAssessmentCompliance, setShips, setUserGroups, setShipUsers } from '../../stores/Actions/Dashboard';
import { Skeleton } from '../../components/Common/Skeleton';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '16px 32px',

        '& .progress': {
            position: 'absolute',
            top: 24,
            right: 24,
        },

        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
    },
    container: {
        maxWidth: 'calc(100vw - 115px)',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'calc(100vw - 33px)',
        },
    },
    topRowCard: {
        width: '100%',
        display: 'block',
        textAlign: 'unset',
        backgroundColor: Colours.white,
        borderRadius: 4,
        position: 'relative',
        '& .MuiCardContent-root': {
            paddingTop: 24,
            paddingBottom: 24,
        },
        '& .col-wrapper': {
            display: 'flex',
            marginTop: 12,
        },
        '& .single-col': {
            width: '100%',
            padding: '12px 24px',
        },
        '& .left-col, .right-col': {
            padding: '12px 24px',
            width: '50%',
        },
        '& .right-col': {
            borderLeft: `1px solid ${Colours.bodyText}`,
        },
        '& .item': {
            marginBottom: 4,
            '& .MuiTypography-h6': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        '& .trend-icon': {
            display: 'block',
            marginLeft: 'auto',
            transform: 'scale(1.5)',
        },
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 80,
        transition: 'all 0.5s ease',
        position: 'relative',
        borderBottom: `1px solid ${Colours.bodyText}`,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        padding: '4px 32px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: Colours.white,
            WebkitBoxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.25)',
            MozBoxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.25)',
            boxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.25)',
        },
        '& .list-img': {
            width: 70,
            height: 50,
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: 4,
            position: 'absolute',
        },
        '& label': {
            pointerEvents: 'none',
        },
        '& .list-val.margin': {
            marginLeft: 82,
        },
        '& .list-val': {
            flex: 1,
            padding: 4,
            '&.wider': {
                flex: 2,
            },
        },
    },
    questionItem: {
        border: `2px solid ${Colours.red}`,
        position: 'relative',
        '& .icon': {
            position: 'absolute',
            top: 4,
            right: 4,
            backgroundColor: Colours.red,
            borderRadius: 64,
            height: 24,
            '& path': {
                fill: Colours.white,
            },
        },
        '&.green': {
            borderColor: Colours.green,
            '& .icon': {
                backgroundColor: Colours.green,
            },
        },
    },
    attemptItem: {
        cursor: 'pointer',
        padding: 16,
        transition: 'all 0.5s ease',
        '&.active': {
            backgroundColor: Colours.primary,
            '& .MuiTypography-root': {
                color: Colours.white + ' !important',
            },
        },
        '&:hover': {
            backgroundColor: Colours.primaryDark,
            '& .MuiTypography-root': {
                color: Colours.white + ' !important',
            },
        },
    },
    header: {
        marginTop: 12,
    },
    skeleton: {
        width: '100%',
        height: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animation: 'skeleton 3s infinite ease-out',
        borderRadius: 4,
        margin: 8,
    },
}));

const DashboardViewState = {
    All: 0,
    Ship: 1,
    User: 2,
    Assessment: 3,
};

function Dashboard(props) {
    const queryStringValue = props.location.search;
    const { shipId, shipName, userId, userName, assessmentId, assessmentName } = queryString.parse(queryStringValue);
    const { Auth, PushHistory, DashboardStore, setCompanyCompliance, setShipCompliance, setAssessmentCompliance, setUserCompliance, setShipUsers, setUserGroups, setShips } = props;
    const { companyCompliance, assessmentCompliance, shipCompliance, userCompliance, shipUsers, userGroups, ships } = DashboardStore;

    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [selectedAttempt, setSelectedAttempt] = React.useState(null);
    const [warning, setWarning] = React.useState(null);
    const [shipsLoading, setShipsLoading] = React.useState(true);
    const [complianceLoading, setComplianceLoading] = React.useState(true);
    const [userGroupsLoading, setUserGroupsLoading] = React.useState(true);
    const [usersLoading, setUsersLoading] = React.useState(true);
    const [lastQuery, setLastQuery] = React.useState(true);

    const classes = useStyles();

    const viewState =
        !isNullOrUndefined(assessmentId) && !isNullOrUndefined(assessmentName) && !isNullOrUndefined(userId) && !isNullOrUndefined(userName)
            ? DashboardViewState.Assessment
            : !isNullOrUndefined(userId) && !isNullOrUndefined(userName)
            ? DashboardViewState.User
            : !isNullOrUndefined(shipId) && !isNullOrUndefined(shipName)
            ? DashboardViewState.Ship
            : DashboardViewState.All;

    const isUser = viewState === DashboardViewState.User;
    const isAssessment = viewState === DashboardViewState.Assessment;

    const getCompliance = () => {
        switch (viewState) {
            case DashboardViewState.All:
                return companyCompliance;
            case DashboardViewState.Ship:
                return shipCompliance[shipId] ?? {};
            case DashboardViewState.User:
                return userCompliance[userId] ?? {};
            case DashboardViewState.Assessment:
                return assessmentCompliance[assessmentId] ?? {};
            default:
                return [];
        }
    };

    const compliance = getCompliance();

    const loadPeripheralUserGroupData = React.useCallback(async (companyId, complianceData) => {
        if (complianceData.userGroupsBest != null || complianceData.userGroupsWorst != null) {
            setUserGroupsLoading(true);
            const response = await UserGroupController.getUserGroups(companyId);
            if (response.hasError) {
                setWarning(response.data);
            } else {
                setUserGroups(response.data);
                setUserGroupsLoading(false);
                window.history.replaceState({ ...window.history.state, userGroupsCache: response.data }, '');
            }
        }
    }, []);

    const loadPeripheralShipData = React.useCallback(
        async (companyId, complianceData) => {
            if (complianceData.shipAverages != null) {
                setShipsLoading(true);
                const response = await ShipController.getShips(companyId);
                if (response.hasError) {
                    setWarning(response.data);
                } else {
                    const prevShips = ships;

                    response.data = response.data.map(e => {
                        const prevShip = prevShips.find(el => el.id === e.id);

                        if (prevShip) {
                            const { logoPath } = prevShip;
                            if (Object.keys(prevShip).includes('logoPath')) {
                                return { ...e, logoPath };
                            }
                        }
                        return e;
                    });

                    setShips(response.data);
                    setShipsLoading(false);

                    for (let i = 0; i < response.data.length; i++) {
                        const prevShip = prevShips.find(el => el.id === response.data[i].id);
                        if (!prevShip) {
                            const imgResponse = await ShipController.getShipLogo(companyId, response.data[i].id);
                            if (imgResponse.hasError) {
                                continue;
                            }
                            response.data[i].logoPath = imgResponse.data;
                        }
                    }

                    setShips(response.data);
                    window.history.replaceState({ ...window.history.state, shipsCache: response.data }, '');
                }
            }
        },
        [ships]
    );

    const loadPeripheralUserData = React.useCallback(
        async (companyId, complianceData) => {
            if (complianceData.userAverages != null) {
                setUsersLoading(true);
                const response = await CompanyController.getCompanyUsers(companyId);
                if (response.hasError) {
                    setWarning(response.data);
                } else {
                    const prevShipUsers = shipUsers;

                    response.data = response.data.map(e => {
                        const prevShipUser = prevShipUsers.find(el => el.id === e.id);

                        if (prevShipUser) {
                            const { imageSrc } = prevShipUser;
                            if (Object.keys(prevShipUser).includes('imageSrc')) {
                                return { ...e, imageSrc };
                            }
                        }

                        return e;
                    });

                    setShipUsers(response.data);
                    setUsersLoading(false);

                    for (let i = 0; i < response.data.length; i++) {
                        const prevShipUser = prevShipUsers.find(el => el.id === response.data[i].id);

                        if (!prevShipUser) {
                            const imgResponse = await CompanyController.getUserProfilePic(companyId, response.data[i].id);
                            if (imgResponse.hasError) {
                                return;
                            }
                            response.data[i].imageSrc = imgResponse.data;
                        }
                    }

                    setShipUsers(response.data);
                    window.history.replaceState({ ...window.history.state, usersCache: response.data }, '');
                }
            }
        },
        [shipUsers]
    );

    const loadPeripheralData = React.useCallback(
        async (companyId, complianceData) => {
            loadPeripheralUserGroupData(companyId, complianceData);
            loadPeripheralUserData(companyId, complianceData);
            loadPeripheralShipData(companyId, complianceData);
            if (!isNullOrUndefined(complianceData.length) && complianceData.length > 0) {
                setSelectedAttempt(complianceData[0].id);
            }
        },
        [ships, shipUsers, loadPeripheralUserGroupData, loadPeripheralUserData, loadPeripheralShipData]
    );

    const loadAllCompliance = React.useCallback(
        async companyId => {
            setComplianceLoading(true);

            const response = await ComplianceController.getCompanyComplianceResults(companyId);
            if (response.hasError) {
                setWarning(response.data);
            } else {
                setCompanyCompliance(response.data);
                setComplianceLoading(false);
                window.history.replaceState({ ...window.history.state, companyComplianceCache: response.data }, '');
                await loadPeripheralData(companyId, response.data);
            }
            setComplianceLoading(false);
        },
        [loadPeripheralData]
    );

    const loadShipCompliance = React.useCallback(
        async companyId => {
            setComplianceLoading(true);
            const response = await ComplianceController.getShipComplianceResults(companyId, shipId);
            if (response.hasError) {
                setWarning(response.data);
            } else {
                const newShipCompliance = { ...shipCompliance, [shipId]: response.data };
                setShipCompliance(newShipCompliance);
                setComplianceLoading(false);
                window.history.replaceState({ ...window.history.state, shipComplianceCache: newShipCompliance }, '');
                await loadPeripheralData(companyId, response.data);
            }
            setComplianceLoading(false);
        },
        [shipId, loadPeripheralData]
    );

    const loadUserCompliance = React.useCallback(
        async companyId => {
            setComplianceLoading(true);
            const response = await ComplianceController.getUserComplianceResults(companyId, userId);
            if (response.hasError) {
                setWarning(response.data);
            } else {
                const newUserCompliance = { ...userCompliance, [userId]: response.data };
                setUserCompliance(newUserCompliance);
                setComplianceLoading(false);
                window.history.replaceState({ ...window.history.state, userComplianceCache: newUserCompliance }, '');
                await loadPeripheralData(companyId, response.data);
            }
            setComplianceLoading(false);
        },
        [userId, loadPeripheralData]
    );

    const loadAssessmentCompliance = React.useCallback(
        async companyId => {
            setComplianceLoading(true);
            const response = await ComplianceController.getComplianceResults(companyId, userId, assessmentId);
            if (response.hasError) {
                setWarning(response.data);
            } else {
                const newUserCompliance = { ...assessmentCompliance, [assessmentId]: response.data };
                setAssessmentCompliance(newUserCompliance);
                setComplianceLoading(false);
                window.history.replaceState({ ...window.history.state, newUserCompliance }, '');
                await loadPeripheralData(companyId, response.data);
            }
            setComplianceLoading(false);
        },
        [userId, assessmentId, loadPeripheralData]
    );

    // init from API & auth check
    React.useEffect(() => {
        if (lastQuery == queryStringValue) return;

        setLastQuery(queryStringValue);

        const { isAuthenticated, isLoggingIn } = Auth;
        if (!isAuthenticated && !isLoggingIn) {
            setRedirectUrl('/Login');
        }

        async function init() {
            const { companyId } = Auth;
            switch (viewState) {
                case DashboardViewState.All:
                    await loadAllCompliance(companyId);
                    break;
                case DashboardViewState.Ship:
                    await loadShipCompliance(companyId);
                    break;
                case DashboardViewState.User:
                    await loadUserCompliance(companyId);
                    break;
                case DashboardViewState.Assessment:
                    await loadAssessmentCompliance(companyId);
                    break;
                default:
                    break;
            }
        }
        init().then(() => {
            setUsersLoading(false);
            setUserGroupsLoading(false);
            setShipsLoading(false);
            setComplianceLoading(false);
            window.history.replaceState({ ...window.history.state, cacheComplete: true }, '');
        });
    }, [Auth, lastQuery, viewState, loadAllCompliance, loadShipCompliance, loadUserCompliance, loadAssessmentCompliance]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
            setRedirectUrl(null);
        }
    }, [redirectUrl, PushHistory]);

    function getAssessmentAttempt() {
        try {
            return compliance.find(e => e.id === selectedAttempt);
        } catch {
            return null;
        }
    }

    function formatGraphValues(graphValues, replaceNullsWithZeroes = false) {
        const src = Object.values(graphValues)
            .reverse()
            .filter(e => moment.utc(e.date).isSameOrBefore(moment.utc().add(1, 'day')));

        const userGroups = [];

        for (let i = 0; i < src.length; i++) {
            for (let j = 0; j < src[i].userGroupeValues.length; j++) {
                const { key } = src[i].userGroupeValues[j];
                if (!userGroups.includes(key)) {
                    userGroups.push(key);
                }
            }
        }

        const missingValue = replaceNullsWithZeroes ? 0 : null;

        const output = { base: src.map(e => (!isNullOrUndefined(e.value) ? Math.round(e.value) : missingValue)) };

        if (isUser) {
            return output;
        }

        for (let i = 0; i < userGroups.length; i++) {
            output[userGroups[i]] = src.map(e => {
                const value = e.userGroupeValues.find(u => u.key === userGroups[i])?.value ?? null;
                return !isNullOrUndefined(value) ? Math.round(value) : missingValue;
            });
        }

        return output;
    }

    function getTitle() {
        switch (viewState) {
            case DashboardViewState.All:
                return 'All Data';
            case DashboardViewState.Ship:
                return shipName;
            case DashboardViewState.User:
                return userName;
            case DashboardViewState.Assessment:
                return userName + ' - ' + assessmentName;
            default:
                return null;
        }
    }

    function buildPageTitle() {
        return <Typography variant="h4">Dashboard - {getTitle()}</Typography>;
    }

    function buildTopRow() {
        return (
            <Grid container spacing={2}>
                {buildTopRowCard(
                    'Worst Performing Questions (Mandatory Assessments)',
                    !isEmpty(compliance)
                        ? compliance.questionsWorst.map(e => ({
                              name: e.title,
                              data: `${e.incorrectlyAnswered} incorrect answers over ${e.totalAnswered} question${e.totalAnswered !== 1 ? 's' : ''}`,
                          }))
                        : [],
                    [],
                    () =>
                        setRedirectUrl(
                            `/OverviewQuestions?${!isNullOrUndefined(shipId) ? `shipId=${shipId}${!isNullOrUndefined(userId) ? '&' : ''}` : ''}${!isNullOrUndefined(userId) ? `userId=${userId}` : ''}`
                        ),
                    true
                )}
                {buildTopRowCard(
                    'Mandatory assessments - Worst & Best Performing',
                    !isEmpty(compliance)
                        ? compliance.assessmentWorst.map(e => ({
                              name: e.title,
                              data: `${scoreToPercentage(e.correctionPercentage, 1)}% correct over ${e.totalAnswered} assessment${e.totalAnswered !== 1 ? 's' : ''}`,
                          }))
                        : [],
                    !isEmpty(compliance)
                        ? compliance.assessmentBest.map(e => ({
                              name: e.title,
                              data: `${scoreToPercentage(e.correctionPercentage, 1)}% correct over ${e.totalAnswered} assessment${e.totalAnswered !== 1 ? 's' : ''}`,
                          }))
                        : [],
                    isUser ? null : () => setRedirectUrl(`/OverviewAssessments?${!isNullOrUndefined(shipId) ? `shipId=${shipId}` : ''}`)
                )}
                {!isUser
                    ? buildTopRowCard(
                          'Best Performing User Groups (Mandatory Assessments)',
                          !isEmpty(compliance)
                              ? compliance.userGroupsBest.map(e => ({
                                    name: userGroups.find(c => c.id === e.title)?.name ?? e.title,
                                    data: `${scoreToPercentage(e.correctionPercentage, 1)}% avg. score over ${e.totalAnswered} assessment${e.totalAnswered !== 1 ? 's' : ''}`,
                                }))
                              : [],
                          [],
                          () => setRedirectUrl(`/OverviewUserGroups?${!isNullOrUndefined(shipId) ? `shipId=${shipId}` : ''}`),
                          true,
                          true
                      )
                    : null}
            </Grid>
        );
    }

    function buildTopRowCard(title, leftOrSingleData = [], rightData = [], onClick, singleColumn = false, trendingUp = false) {
        const renderItem = (item, index) => {
            return (
                <div className="item" key={index}>
                    <Tooltip title={item?.name ?? ''}>
                        <Typography variant="h6" component="div">
                            {item.name}
                        </Typography>
                    </Tooltip>
                    <Typography variant="caption" component="div">
                        {item.data}
                    </Typography>
                </div>
            );
        };

        return (
            <Grid item lg={6} md={12} sm={12} xs={12}>
                <Card className={classes.topRowCard} component={ButtonBase} disabled={isNullOrUndefined(onClick)} onClick={onClick}>
                    <CardContent>
                        <Typography variant="caption" component="div" align="center">
                            {title.toUpperCase()}
                        </Typography>
                        <Typography variant="caption" component="div" align="center">
                            Hover over items for more info
                        </Typography>
                        {complianceLoading ? <CircularProgress size={24} className="progress" /> : null}
                        <div className="col-wrapper">
                            <div className={`${singleColumn ? 'single-col' : 'left-col'}`}>
                                {singleColumn && trendingUp ? <TrendingUp className="trend-icon" /> : <TrendingDown className="trend-icon" />}
                                {leftOrSingleData.reverse().map(renderItem)}
                            </div>
                            {singleColumn ? null : (
                                <div className="right-col">
                                    <TrendingUp className="trend-icon" />
                                    {rightData.reverse().map(renderItem)}
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    function buildGraphs() {
        if (isNullOrUndefined(compliance)) {
            return null;
        }

        let questionsLabels = [];
        let questionsSeries = [];
        let questionsLegend = [];
        let answersLabels = [];
        let answersSeries = [];
        let answersLegend = [];
        let scoreLabels = [];
        let scoreSeries = [];
        let scoreLegend = [];

        switch (viewState) {
            case DashboardViewState.All:
            case DashboardViewState.Ship:
            case DashboardViewState.User:
                if (!isNullOrUndefined(compliance.assessmentGraphData)) {
                    const formatted = formatGraphValues(compliance.assessmentGraphData, true);
                    questionsSeries = Object.values(formatted);
                    questionsLabels = Object.values(compliance.assessmentGraphData)
                        .reverse()
                        .filter(e => moment.utc(e.date).isSameOrBefore(moment.utc()))
                        .map(e => moment.utc(e.date).local().format('MM.YYYY'));
                    questionsLegend = [
                        getTitle(),
                        ...Object.keys(formatted)
                            .map(e => userGroups.find(u => u.id === e)?.name ?? 'Loading')
                            .slice(1),
                    ];
                }
                if (!isNullOrUndefined(compliance.scoresGraphData)) {
                    const formatted = formatGraphValues(compliance.scoresGraphData);
                    answersSeries = Object.values(formatted);
                    answersLabels = Object.values(compliance.scoresGraphData)
                        .reverse()
                        .filter(e => moment.utc(e.date).isSameOrBefore(moment.utc()))
                        .map(e => moment.utc(e.date).local().format('MM.YYYY'));
                    answersLegend = [
                        getTitle(),
                        ...Object.keys(formatted)
                            .map(e => userGroups.find(u => u.id === e)?.name ?? 'Loading')
                            .slice(1),
                    ];
                }
                break;
            case DashboardViewState.Assessment:
                if (!isNullOrUndefined(compliance.length)) {
                    scoreSeries = [
                        {
                            name: 'series-0',
                            data: compliance
                                .map(e => ({
                                    meta: scoreToPercentage(e.totalScore, 1),
                                    x: moment.utc(e.taken).local().unix() * 60 * 60 * 24,
                                    y: scoreToPercentage(e.totalScore, 1),
                                }))
                                .reverse(),
                        },
                    ];
                    scoreLabels = [];
                    scoreLegend = [getTitle()];
                }
                break;
            default:
                break;
        }

        return (
            <Grid container spacing={2}>
                {!isAssessment ? (
                    <>
                        <Grid item md={6} sm={12} xs={12}>
                            <DashboardGraph
                                title={'Mandatory assessments completed over time'}
                                labels={questionsLabels}
                                series={questionsSeries}
                                legend={questionsLegend}
                                loading={complianceLoading || userGroupsLoading}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <DashboardGraph
                                title={'Average score percentage (mandatory assessments) over time'}
                                labels={answersLabels}
                                series={answersSeries}
                                legend={answersLegend}
                                loading={complianceLoading || userGroupsLoading}
                            />
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12}>
                        <DashboardGraph title={'Score percentage over time'} labels={scoreLabels} series={scoreSeries} legend={scoreLegend} scatterGraph={true} />
                    </Grid>
                )}
            </Grid>
        );
    }

    function buildList() {
        const getListTitle = () => {
            switch (viewState) {
                case DashboardViewState.All:
                    return 'Ships';
                case DashboardViewState.Ship:
                    return 'Crew Members';
                case DashboardViewState.User:
                    return 'Assessment';
                case DashboardViewState.Assessment:
                    return isNullOrUndefined(getAssessmentAttempt()) ? 'Assessment Date' : moment.utc(getAssessmentAttempt().taken).local().format('DD.MM.YYYY (HH:mm)');
                default:
                    return 'Invalid State';
            }
        };
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" className={classes.header}>
                        {getListTitle()}
                    </Typography>
                    {buildListTitleLoadingSpinner()}
                </Grid>
                {buildListItems()}
            </Grid>
        );
    }

    function buildListTitleLoadingSpinner() {
        switch (viewState) {
            case DashboardViewState.All:
                return shipsLoading ? <CircularProgress size={24} style={{ marginTop: 12, marginLeft: 16 }} /> : null;
            case DashboardViewState.Ship:
                return usersLoading ? <CircularProgress size={24} style={{ marginTop: 12, marginLeft: 16 }} /> : null;
            case DashboardViewState.Assessment:
                return complianceLoading ? <CircularProgress size={24} style={{ marginTop: 12, marginLeft: 16 }} /> : null;
            case DashboardViewState.User:
                return complianceLoading ? <CircularProgress size={24} style={{ marginTop: 12, marginLeft: 16 }} /> : null;
            default:
                return null;
        }
    }

    function buildListItems() {
        if (isEmpty(compliance)) {
            return <Skeleton />;
        }

        switch (viewState) {
            case DashboardViewState.All:
                if (!compliance.shipAverages && !ships) {
                    return [];
                }
                if (ships.length > 0) {
                    return Object.entries(compliance.shipAverages)
                        .sort((a, b) => b[1] - a[1])
                        .filter(e => ships.find(s => s.id === e[0]))
                        .map((e, i) => {
                            const ship = ships.find(s => s.id === e[0]);
                            const shipItem = { name: ship.name, imgUrl: ship.logoPath, id: e[0], avgCorrectAnswers: e[1] };
                            return buildImageItem(shipItem, i, true);
                        });
                } else {
                    return <Skeleton />;
                }
            case DashboardViewState.Ship:
                if (!isNullOrUndefined(compliance.userAverages) && !isNullOrUndefined(shipUsers) && shipUsers.length > 0) {
                    return Object.entries(compliance.userAverages)
                        .filter(e => !isNullOrUndefined(shipUsers.find(u => u.userName === e[0])))
                        .sort((a, b) => b[1] - a[1])
                        .map((e, i) => {
                            const user = shipUsers.find(u => u.userName === e[0]);
                            const userItem = { name: `${user?.firstName} ${user?.lastName}`, imgUrl: user.imageSrc, id: user.id, avgCorrectAnswers: e[1] };
                            return buildImageItem(userItem, i, false);
                        });
                } else {
                    return <Skeleton />;
                }
            case DashboardViewState.User:
                if (!isNullOrUndefined(compliance.compianceResults)) {
                    return Object.entries(compliance.compianceResults).map((e, i) => {
                        const compliance = e[1];
                        const userItem = {
                            name: compliance.title,
                            questionsAnswers: compliance.questionsAnswers,
                            correctAnswers: compliance.correctAnswers,
                            averageScore: compliance.averageScore,
                            id: compliance.compianceTestId,
                            lastAttempt: compliance.lastAttempt,
                            totalAttempts: compliance.attempts,
                        };
                        return buildAssessmentItem(userItem, i);
                    });
                } else {
                    return <Skeleton />;
                }
            case DashboardViewState.Assessment:
                if (!isNullOrUndefined(compliance?.length) && !isNullOrUndefined(selectedAttempt)) {
                    const attempt = getAssessmentAttempt();
                    return (attempt?.questions ?? []).map(e =>
                        buildQuestionItem({
                            id: e.id,
                            title: e.title,
                            userAnswer: e.selectedAnswers[0]?.title ?? 'Question Skipped',
                            correctAnswer: e.answers?.find(a => a.isCorrect)?.title ?? 'No Correct Answer Found',
                            correct: e.answers?.find(a => a.id === e.selectedAnswers[0]?.id)?.isCorrect ?? false,
                            skipped: e.selectedAnswers.length === 0,
                        })
                    );
                } else {
                    return <Skeleton />;
                }
            default:
                return [];
        }
    }

    function buildImageItem(item, index, isShip) {
        const { name, avgCorrectAnswers, id, imgUrl } = item;

        const type = isShip ? 'ship' : 'user';
        const func = () => setRedirectUrl(`/?${type}Id=${id}&${type}Name=${name}`);
        return (
            <Grid item lg={6} md={12} sm={12} xs={12} key={index}>
                <div className={classes.listItem} onClick={func}>
                    <img src={!isNullOrUndefined(imgUrl) && imgUrl !== '' ? imgUrl : NoImage} alt={name} className="list-img" />
                    <div className="list-val margin">
                        <label>Name</label>
                        <br />
                        <b>{name}</b>
                    </div>
                    <div className="list-val">
                        <label>Average Score (Last 3 Months)</label>
                        <br />
                        <b>{(avgCorrectAnswers * 100).toFixed(2)}%</b>
                    </div>
                </div>
            </Grid>
        );
    }

    function buildAssessmentItem(assessment, index) {
        const { name, averageScore, lastAttempt, totalAttempts, id } = assessment;

        return (
            <Grid item lg={6} md={12} sm={12} xs={12} key={index}>
                <div className={classes.listItem} onClick={() => setRedirectUrl(`/?userId=${userId}&userName=${userName}&assessmentId=${id}&assessmentName=${name}`)}>
                    <div className="list-val wider">
                        <label>Name</label>
                        <br />
                        <b>{name}</b>
                    </div>
                    <div className="list-val">
                        <label>Average Score</label>
                        <br />
                        <b>{(averageScore * 100).toFixed(2)}%</b>
                    </div>
                    <div className="list-val">
                        <label>Last Attempt</label>
                        <br />
                        <b>{moment.utc(lastAttempt).local().format('DD.MM.YYYY')}</b>
                    </div>
                    <div className="list-val">
                        <label>Total Attempts</label>
                        <br />
                        <b>{totalAttempts}</b>
                    </div>
                </div>
            </Grid>
        );
    }

    function buildQuestionItem(question) {
        const { id, title, userAnswer, correctAnswer, correct, skipped } = question;

        return (
            <Grid item lg={3} md={4} sm={6} xs={12} key={id}>
                <Card className={`${classes.questionItem}${correct ? ' green' : ''}`}>
                    <div className="icon">{!correct ? <Close /> : <Done />}</div>
                    <CardHeader title={'Q' + id.toString()} />
                    <Divider />
                    <CardContent>
                        <Typography variant="body1" gutterBottom>
                            {title}
                        </Typography>
                        <br />
                        <Typography style={{ color: skipped ? 'red' : 'black' }} variant="body2">
                            {!skipped ? (
                                <>
                                    <b>User Answer:</b>
                                    <br />
                                </>
                            ) : null}
                            {userAnswer}
                        </Typography>
                        {!correct ? (
                            <>
                                <br />
                                <Typography variant="body2">
                                    <b>Correct Answer:</b>
                                    <br />
                                    {correctAnswer}
                                </Typography>
                            </>
                        ) : null}
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    function buildAssessmentAttempts() {
        if (isNullOrUndefined(compliance.length)) {
            return null;
        }
        const mappedAttempts = compliance.map(e => ({
            date: e.taken,
            score: `${scoreToPercentage(e.totalScore, 1)}%`,
            id: e.id,
        }));
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" className={classes.header}>
                        Assessment Attempts
                    </Typography>
                </Grid>
                {mappedAttempts.map((e, i) => {
                    const { date, score, id } = e;
                    return (
                        <Grid item md={2} sm={4} xs={12} key={i}>
                            <Card className={`${classes.attemptItem}${selectedAttempt === id ? ' active' : ''}`} onClick={() => setSelectedAttempt(id)}>
                                <Typography>
                                    <b>{moment.utc(date).local().format('DD.MM.YYYY (HH:mm)')}</b>
                                </Typography>
                                <Typography>Score: {score}</Typography>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    return (
        <div className={classes.wrapper}>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                    {buildPageTitle()}
                </Grid>
                <Grid item xs={12}>
                    <Alert header="Something went wrong!" text={warning} />
                </Grid>
                {!isAssessment ? (
                    <Grid item xs={12}>
                        {buildTopRow()}
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    {buildGraphs()}
                </Grid>
                {isAssessment ? (
                    <Grid item xs={12}>
                        {buildAssessmentAttempts()}
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    {buildList()}
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
    DashboardStore: state.Dashboard,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    setCompanyCompliance: items => dispatch(setCompanyCompliance(items)),
    setShipCompliance: items => dispatch(setShipCompliance(items)),
    setUserCompliance: items => dispatch(setUserCompliance(items)),
    setAssessmentCompliance: items => dispatch(setAssessmentCompliance(items)),
    setShipUsers: items => dispatch(setShipUsers(items)),
    setUserGroups: items => dispatch(setUserGroups(items)),
    setShips: items => dispatch(setShips(items)),
    clearStore: () => dispatch(clearStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

Dashboard.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    DashboardStore: PropTypes.object,
    location: PropTypes.object,
    setCompanyCompliance: PropTypes.func,
    setShipCompliance: PropTypes.func,
    setUserCompliance: PropTypes.func,
    setAssessmentCompliance: PropTypes.func,
    setShipUsers: PropTypes.func,
    setUserGroups: PropTypes.func,
    setShips: PropTypes.func,
    clearStore: PropTypes.func,
};
